import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import seta from "../../../../images/mobile/solutions/arrow-desk.svg"
import academy from "../../../../images/illustrations/academy.svg"

import "./SectionOne.scss"

const SectionOne = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: 50,
    })
  }, [])

  return (
    <div id="fabacademy-section-one">
      <div id="content">
        <div className="section-one-text">
          <h1 className="title" data-aos="fade-right">
            FAB academy
          </h1>
          <p data-aos="fade-right">
            Desenvolvemos as principais habilidades da era da transformação
            digital através das nossas academias rápidas e práticas.
          </p>
        </div>
        <div className="section-one-image">
          <img
            src={academy}
            alt="Uma cabeça dividida em quadros. Onde um é um olho, o outro um relógio conectado a uma bateria e por último pernas correndo."
            data-aos="fade-left"
          />
          <div className="section-one-member">
            <img src={seta} alt="arrow" className="arrow bounce-infinite" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionOne
