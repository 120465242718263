import React from "react"
import Slider from "react-slick"
import Arrow from "./Arrow/Arrow"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Testimonials.scss"

const Testimonials = ({ testimonials }) => {
  const settings = {
    dots: false,
    draggable: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: <Arrow mirror />,
    prevArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1279,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div id="testimonials">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div id={testimonial.client.name} className="testimonial" key={index}>
            <div className="image-container">
              <img
                src={testimonial.client.img}
                alt={`Foto representando ${testimonial.client.name}`}
              />
              <p>
                <strong>{testimonial.client.name}</strong>
              </p>
              <p className="role">{testimonial.client.role}</p>
            </div>
            <div className="content">
              <p>{testimonial.text}</p>
              <img
                id={testimonial.enterpriseName.split(" ")[0]}
                src={testimonial.logo}
                alt={"Logo " + testimonial.enterpriseName}
                className="logo"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Testimonials
