import React from "react"
import Slider from "react-slick"

import SampleArrow from "./SampleArrow/SampleArrow"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Carousel.scss"

const Carousel = ({ team = [], fontSizeMinor = false }) => {
  // const ICON_COLOR = "#000"
  // const COLOR_DEACTIVATE = "#c4c4c47f"
  // const COLOR_ACTIVATE = "#c4c4c4"
  const COLORS_FAB = {
    ACADEMY: "#eb6620",
    CORPORATE: "#f7ca45",
    TECH: "#56b265",
  }

  let settings = {
    dots: false,
    draggable: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 15000,
    nextArrow: <SampleArrow mirror />,
    prevArrow: <SampleArrow />,
    responsive: [
      {
        breakpoint: 1919,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 749,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 369,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  function getHoverBackgroundColor(index) {
    if ((index + 1) % 3 === 0) return COLORS_FAB.CORPORATE
    if ((index + 1) % 2 === 0) return COLORS_FAB.ACADEMY
    return COLORS_FAB.TECH
  }

  return (
    <div id="carousel">
      <Slider {...settings}>
        {team.map((member, index) => (
          <div className={`item`} key={index} id={index}>
            <img src={member.image} alt={member.name} className="image" />
            <div
              className="image-hover"
              style={{ backgroundColor: getHoverBackgroundColor(index) }}
            >
              <p className="name">{member.name}</p>
              <p className={`role ${fontSizeMinor ? "font-minor" : ""}`}>
                {member.role}
              </p>
              <div className="social-networks">
                <div className="linkedin">
                  <a href={member.linkedin} target="_blank" rel="noreferrer">
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="#FFFFFF"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.60207 11.25H0.591858V33.4694H7.60207V11.25Z"
                        fill="#FFFFFF"
                        style={{
                          fill:
                            getHoverBackgroundColor(index) ===
                            COLORS_FAB.CORPORATE
                              ? "#000000"
                              : "#FFFFFF",
                        }}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.19389 4.09695C8.19389 6.36226 6.35715 8.1939 4.09694 8.1939C1.83163 8.1939 0 6.35716 0 4.09695C0 1.83675 1.83674 1.06036e-05 4.09694 1.06036e-05C6.35715 -0.00509144 8.19389 1.83165 8.19389 4.09695Z"
                        fill="#FFFFFF"
                        style={{
                          fill:
                            getHoverBackgroundColor(index) ===
                            COLORS_FAB.CORPORATE
                              ? "#000000"
                              : "#FFFFFF",
                        }}
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.5765 10.4287C21.8265 10.4287 19.8009 12.8879 19.2193 13.7961V11.2501H12.2091V33.4696H19.2193V20.3522C19.2193 20.3522 19.8265 16.4491 23.1224 16.4491C26.4183 16.4491 26.9948 20.0308 26.9948 21.041V33.5716H33.9999V20.3573C33.9999 16.1277 32.3979 10.4287 26.5765 10.4287Z"
                        fill="#FFFFFF"
                        style={{
                          fill:
                            getHoverBackgroundColor(index) ===
                            COLORS_FAB.CORPORATE
                              ? "#000000"
                              : "#FFFFFF",
                        }}
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carousel
