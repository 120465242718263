import React from "react"
import Slider from "react-slick"

import DarkArrow from "./DarkArrow/DarkArrow"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./CarouselDark.scss"

const CarouselDark = ({ classOfItems, items }) => {
  let settings = {
    dots: false,
    draggable: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <DarkArrow mirror />,
    prevArrow: <DarkArrow />,
    responsive: [
      {
        breakpoint: 1759,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  /**
   *
   * @param {string} id
   */

  return (
    <div id="carousel-dark">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div class={classOfItems} key={index}>
            <div className="divider">
              <div className="image-container">
                <img src={item.img} alt="" />
              </div>
              <div className="title-container">
                <p>{item.title}</p>
              </div>
              <div
                id={`description-${index}`}
                className="description-container expand"
              >
                <p>{item.description}</p>
              </div>

              {/* <button
                className="expand-btn btn"
                onClick={() => handleExpandDescription(`description-${index}`)}
              >
                <FiChevronDown size={32} />
              </button> */}
            </div>
            <a className="know-more-button" href="/contact">
              <button className="btn">Saiba Mais</button>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CarouselDark
