import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import miguel from "../../../../images/pictures/time/miguel.png"
import jefferson from "../../../../images/pictures/time/jefferson.png"
import eriko from "../../../../images/pictures/time/eriko.png"
import ricardo from "../../../../images/pictures/time/ricardo.png"

import "./SectionSix.scss"
import Carousel from "../../../Carousel/Carousel"

const SectionSix = ({ style }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  }, [])
  return (
    <div id="fabacademy-section-six" data-aos="fade-up" style={style}>
      <h1 id="title" data-aos="fade-up" data-aos-delay="300">
        Nossos transformadores
      </h1>
      <div>
        <Carousel
          fontSizeMinor={true}
          team={[
            {
              name: "Miguel Isoni Filho",
              image: miguel,
              role: "Academia Data-Tech Mindset ",
              linkedin: "https://www.linkedin.com/in/miguelisonifilho/",
            },
            {
              name: "Jefferson Barbosa",
              image: jefferson,
              role: "Expert em Ciência de Dados",
              linkedin:
                "https://www.linkedin.com/in/jefferson-ferreira-barbosa-mba-msc-22485212/",
            },
            {
              name: "Eriko Brito",
              image: eriko,
              role: "Academia Gestão Ágil",
              linkedin: "https://www.linkedin.com/in/erikobrito/",
            },

            {
              name: "Ricardo Roberto",
              image: ricardo,
              role: "Chatbot Para Não Programadores",
              linkedin:
                "https://www.linkedin.com/in/ricardo-roberto-lima-1504aa7b/",
            },
          ]}
        />
      </div>
    </div>
  )
}

export default SectionSix
