import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

import armazempb from "../../../../images/enterprises-colorized/armazempb.svg"
import gruponeves from "../../../../images/enterprises-colorized/gruponeves.svg"
import gerdau from "../../../../images/enterprises-colorized/gerdau.svg"
import tvCaboBranco from "../../../../images/enterprises-colorized/tv-cabo-branco.svg"
import energisa from "../../../../images/enterprises-colorized/energisa.svg"
import stone from "../../../../images/enterprises-colorized/stone.svg"
import hostdime from "../../../../images/enterprises-colorized/hostdime.svg"
import elfa from "../../../../images/enterprises-colorized/elfa.svg"
import leroy_merlin from "../../../../images/enterprises-colorized/leroy_merlin_serialized.svg"

import "./SectionThree.scss"

const SectionThree = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  }, [])

  return (
    <div id="fabacademy-section-three">
      <div className="in-company" data-aos="fade-right">
        <h1>In Company</h1>
        <p>
          As academias <b>In Company</b> são excepcionais para empresas que
          desejam um treinamento específico, pontual e personalizado.
        </p>
        <button className="btn">personalizar academia</button>
      </div>
      <div className="enterprises" data-aos="fade-left">
        <div className="enterprises-container">
          <div className="enterprise">
            <img src={armazempb} id="armazempb" alt="Logo: Armazém Paraíba" />
          </div>
          <div className="enterprise">
            <img src={gerdau} id="gerdau" alt="Logo: Gerdau" />
          </div>
          <div className="enterprise">
            <img
              src={tvCaboBranco}
              id="tvcabobranco"
              alt="Logo: TV Cabo Branco"
            />
          </div>
          <div className="enterprise">
            <img src={gruponeves} id="gruponeves" alt="Logo: Grupo Neves" />
          </div>
          <div className="enterprise">
            <img src={energisa} id="energisa" alt="Logo: Energisa" />
          </div>
          <div className="enterprise">
            <img src={stone} id="stone" alt="Logo: Stone" />
          </div>
          <div className="enterprise">
            <img src={hostdime} id="hostdime" alt="Logo: Hostdime" />
          </div>
          <div className="enterprise">
            <img
              src={leroy_merlin}
              id="leroy-merlin"
              alt="Logo: Leroy Merlin"
            />
          </div>
          <div className="enterprise">
            <img src={elfa} id="elfa" alt="Logo: Elfa" />
          </div>
        </div>
        <p>Organizações impactadas</p>
      </div>
    </div>
  )
}

export default SectionThree
