import React, { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import Testimonials from "../../../Testimonials/Testimonials"

// import picture3 from "../../../../images/pictures/picture3.jpg"
import ruyDantas from "../../../../images/pictures/testmonials/ruy-dantas.jpeg"
import raiff from "../../../../images/pictures/testmonials/raiff.jpg"
import flavia from "../../../../images/pictures/testmonials/flavia-tabosa.png"
import sin from "../../../../images/enterprises-colorized/sin.png"
import r3 from "../../../../images/enterprises-colorized/r3.jpeg"
import hostdime from "../../../../images/enterprises-colorized/hostdime.svg"

import "./SectionSeven.scss"

const SectionSeven = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
    })
  }, [])

  return (
    <div id="fabacademy-section-seven">
      <h1 data-aos="fade-up">Depoimentos</h1>
      <Testimonials
        testimonials={[
          {
            client: {
              name: "Ruy Dantas",
              role: "Presidente do Sin Group",
              img: ruyDantas,
            },
            text:
              "A FABWORK nos ajudou a acelerar o processo de desenvolvimento de projetos, em times, fracionando o todo em entregas dentro de ciclos determinados, com envolvimento do cliente. Isso acelerou nosso processo de inovação e implementou novos modelos de negócio.",
            logo: sin,
            enterpriseName: "Sin group",
          },
          {
            client: {
              name: "Flávia Tabosa",
              role: "Gerente de Marketing na HostDime Brasil",
              img: flavia,
            },
            text:
              "Estive no curso inaugural da FABWORK e com todo o ânimo do começo, fomos acolhidos num espaço que nos incentivou a aprender, aguçar a curiosidade e interligar experiências e aflorar o ingrediente que nos faz preservar nossas ideias: a paixão.",
            logo: hostdime,
            enterpriseName: "Hostdime",
          },
          {
            client: {
              name: "Raiff Figueiredo",
              role: "CTO da R3 Sistemas",
              img: raiff,
            },
            text:
              "A academia Data Science Para Não Programadores foi um divisor de águas na compreensão dos conceitos de machine learning e mineração de dados. Agora consigo enxergar um mundo de possibilidades de como tratar e extrair informações para enriquecer tomada de decisões.",
            logo: r3,
            enterpriseName: "R3 Sistemas",
          },
        ]}
      />
    </div>
  )
}

export default SectionSeven
