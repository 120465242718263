import React from "react"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"

import "./Arrow.scss"

const Arrow = props => {
  const ICON_COLOR = "#000000"
  const { className, style, onClick, mirror = false } = props

  return (
    <button
      id="sample-arrow"
      className={`arrow btn ${className}`}
      style={{
        ...style,
        display: "block",
      }}
      onClick={onClick}
    >
      {!mirror ? (
        <FiChevronLeft color={ICON_COLOR} />
      ) : (
        <FiChevronRight color={ICON_COLOR} />
      )}
    </button>
  )
}

export default Arrow
