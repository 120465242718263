import React from "react"
import CarouselDark from "../../../CarouselDark/CarouselDark"

import agil from "../../../../images/illustrations/fabacademy/academies/agil.svg"
import chatbot from "../../../../images/illustrations/fabacademy/academies/chatbot.svg"
import dataScience from "../../../../images/illustrations/fabacademy/academies/data-science.svg"
import mindset from "../../../../images/illustrations/fabacademy/academies/mindset.svg"
import executive from "../../../../images/illustrations/fabacademy/academies/executive.svg"

import "./SectionFour.scss"

const SectionFour = () => {
  return (
    <div id="fabacademy-section-four">
      <h1>Nossas Academias</h1>
      <CarouselDark
        classOfItems="academies"
        items={[
          {
            img: dataScience,
            title: "Data Science Para Não Programadores",
            description:
              "Visa desenvolver profissionais que possam aplicar os conceitos de Ciência de Dados (DataScience) nas suas respectivas áreas e, dessa forma, extrair todo o potencial que seus dados têm para alcançar o diferencial competitivo nos negócios.",
            url: "",
          },
          {
            img: chatbot,
            title: "Data Business",
            description:
              "Tem o objetivo de apresentar os conceitos práticos de data analytics e data science para que os participantes possam refletir e aplicar ações data-driven em sua rotina de negócios.",
            url: "/contact/",
          },
          {
            img: executive,
            title: "Data Executive",
            description:
              "Uma academia para apoiar líderes na compreensão do universo dos dados de forma prática e descomplicada, exercitando a mente a pensar em como modificar sua rotina e gestão de times para uma cultura mais analítica. Ela visa apresentar os cases práticos de data analytics e data science para que os líderes presentes possam refletir e aplicar ações data driven em sua rotina de negócios.",
            url: "",
          },
          {
            img: mindset,
            title: "Data-Tech Mindset",
            description:
              "Visa conscientizar os colaboradores para o mundo dos negócios, com foco no pilar de pessoas e na construção da mentalidade (mindset) de crescimento direcionada a dados - “data driven”.",
            url: "",
          },
          {
            img: agil,
            title: "Gestão Ágil",
            description:
              "A academia tem o objetivo de apresentar os fundamentos da gestão ágil e desenvolver as principais técnicas do framework SCRUM, utilizando softwares específicos e simulação virtual de processos empresariais.",
            url: "",
          },
        ]}
      />
    </div>
  )
}

export default SectionFour
