import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

import SectionOne from "../components/Solutions/Fabacademy/SectionOne/SectionOne"
import SectionTwo from "../components/Solutions/Fabacademy/SectionTwo/SectionTwo"
import SectionFive from "../components/Solutions/Fabacademy/SectionFive/SectionFive"
import SectionSix from "../components/Solutions/Fabacademy/SectionSix/SectionSix"
import SectionThree from "../components/Solutions/Fabacademy/SectionThree/SectionThree"
import SectionFour from "../components/Solutions/Fabacademy/SectionFour/SectionFour"
import SectionSeven from "../components/Solutions/Fabacademy/SectionSeven/SectionSeven"
import NeoronWidget from "../utils/NeoronWidget"

export default function Fabacademy() {
  useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
    document.getElementById("label").style.backgroundColor = "#EB6620"
  })
  return (
    <div style={{ width: "100%" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FAB academy | FABWORK</title>
        <link rel="canonical" href="https://fab.work/fabacademy/" />
      </Helmet>
      <Header />
      {/*<div style={{ display: "none" }}></div>*/}
      <SectionOne />
      <SectionFour />
      <SectionTwo />
      <SectionThree />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <Footer />
    </div>
  )
}
