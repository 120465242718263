import "./SectionTwo.scss"
import all_line from "../../../../images/pictures/allline.svg"
import React from "react"

function SectionTwo() {
  return (
    <div id="fabacademy-section-two">
      <div id="section-orange">
        <img
          src={all_line}
          alt="No centro da figura um rosto de lado cercao por outros dois rostos, um dispositívo móvem e um computador. Todos os componentes da figura estão interligados por linhas que representa o All Line."
        />
        <div className="text-orange">
          <h1 className="title">Somos All Line com maestria!</h1>
          <p id="text">
            Ao longo de 2020, aplicando a nossa metodologia IECEI, mostramos na
            prática para clientes como Gerdau e Leroy Merlin que somos capazes
            de entregar de forma online e ao vivo as nossas academias com
            maestria, prendendo a atenção dos nossos alunos, gamificando a
            experiência de aprendizado e aumentando de forma significativa o
            conhecimento aplicado.
          </p>
          <p id="text">
            Já éramos, desde 2018, craques na formação offline (presencial), e
            agora podemos dizer com precisão que estamos prontos para continuar
            a nossa expansão no mundo online também. Lembramos que ministrar as
            nossas academias de forma online e ao vivo ainda elimina custos de
            deslocamento e riscos epidemiológicos.
          </p>
        </div>
      </div>
      <div id="content">
        <div className="text">
          <h1>+850</h1>
          <p>profissionais transformados</p>
        </div>

        <div className="text">
          <h1>+45</h1>
          <p className="text-2">academias realizadas</p>
        </div>

        <div className="text">
          <h1>+25</h1>
          <p className="text-2">empresas impactadas</p>
        </div>
      </div>
    </div>
  )
}

export default SectionTwo
